import React from "react"
import "./MlModelEngineering.css"
const MlModelEngineering = () => {
  const industries = [
    {
      title: "Healthcare",
      description: [
        "Develop predictive models to enhance patient care and treatment plans.",
        "Create diagnostic models that assist healthcare providers in identifying conditions faster and more accurately.",
        "Improve clinical decision-making by analyzing patient data and medical records.",
      ],
      icon: (
        <i
          className="icon-machine-learning-development-healthcare"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Finance",
      description: [
        "Build fraud detection models that help identify suspicious transactions in real-time.",
        "Optimize credit scoring and loan approval processes through data-driven risk assessment models.",
        "Forecast market trends using advanced predictive analytics.",
      ],
      icon: (
        <i
          className="icon-machine-learning-development-finance"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Retail",
      description: [
        "Personalize customer experiences with recommendation systems that offer relevant product suggestions.",
        "Predict inventory needs with demand forecasting models.",
        "Optimize pricing strategies based on customer behavior and market trends.",
      ],
      icon: (
        <i
          className="icon-machine-learning-development-retail"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Manufacturing",
      description: [
        "Implement machine learning models to predict equipment failure and optimize maintenance schedules.",
        "Improve production processes through real-time data analytics and optimization models.",
        "Reduce waste and improve efficiency using data-driven decision models.",
      ],
      icon: (
        <i
          className="icon-machine-learning-development-manufacturing"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },

    {
      title: "Automotive",
      description: [
        "Enhance autonomous driving systems through machine learning-based decision-making models.",
        "Develop predictive maintenance models that help prevent vehicle breakdowns before they happen.",
        "Create models for real-time traffic analysis and route optimization.",
      ],
      icon: (
        <i
          className="icon-machine-learning-development-automotive"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Education",
      description: [
        "Develop personalized recommendation systems to boost customer engagement and drive sales.",
        "Optimize inventory management and demand forecasting for seamless operations.",
        "Enhance customer experience with predictive models that suggest relevant products based on browsing behavior.",
      ],
      icon: (
        <i
          className="icon-machine-learning-development-education"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
  ]

  return (
    <div
      className="min-h-screen flex items-center justify-center text-white bg-cover bg-center relative"
      style={{
        backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2025/02/Industries-We-Serve-with-Machine-Learning-Solutions.webp")`,
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="relative z-10 p-6 md:p-16 rounded-lg w-full">
        {/* Title */}
        <h1 className="text-3xl md:text-5xl font-bold my-4 md:mb-6 text-center text-white">
          Industries We Serve with Machine Learning Model Engineering
        </h1>

        {/* Subtitle */}
        <p className="text-lg md:text-xl text-gray-300 text-center  mx-auto mb-8">
          Our ML model engineering services have been successfully applied to a
          wide range of industries. Here’s how we’re helping businesses unlock
          new opportunities:
        </p>

        {/* Industry Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full mx-auto gap-4">
          {industries.map((industry, index) => (
            <div className="feature-tagMlEng">
              <div className="flex flex-col items-start h-full">
                <div
                  className="w-20 h-20 md:w-24 md:h-24 lg:w-26 lg:h-26 rounded-2xl text-white flex items-center justify-center mb-6 text-2xl md:text-4xl lg:text-6xl"
                  style={{
                    background: "rgba(255, 255, 255, 0.10)",
                  }}
                >
                  {industry.icon}
                </div>
                <h3 className="text-xl lg:text-2xl font-bold text-white mb-4">
                  {industry.title}
                </h3>
                <div className="text-left font-normal text-lg text-white overflow-hidden">
                  <ul className="list-disc pl-5 space-y-2">
                    {industry.description.map((point, idx) => (
                      <li key={idx} className="text-lg text-gray-200">
                        {point}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MlModelEngineering
